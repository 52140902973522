export const enterpriseDomainLocal = "http://localhost:4000/portal/api";
export const enterpriseDomainDev =
  "https://enterprise.boingogcpdev.com/portal/api";
export const enterpriseDomainQA = "https://enterprise.boingoqa.com/portal/api";
export const enterpriseDomainProd = "https://enterprise.boingo.com/portal/api";

export const selfcareDomainProd = "https://selfcare.boingohotspot.net/login";
export const selfcareDomainDevQaStg =
  "https://selfcare-qa-stg.boingohotspot.net/login";

// API Endpoints
export const PORTAL_API_ENDPOINT = process.env.REACT_APP_API_PORTAL || "";

// Braintree Token
const BRAINTREE_AUTH_TOKEN_SANDBOX = "sandbox_6m863gmh_gwtp8qvdpq6ftkxf";
const BRAINTREE_AUTH_TOKEN_PROD = "production_q7fzphz5_tdcvffwq2bmzqmxj";

// return domain
export const getEnterpriseUrl = (host) => {
  if (host.includes(".boingo.com")) {
    return enterpriseDomainProd;
  } else if (host.includes("localhost")) {
    return enterpriseDomainLocal;
  } else if (host.includes(".boingogcpdev.com")) {
    return enterpriseDomainDev;
  } else if (host.includes(".boingoqa.com")) {
    return enterpriseDomainQA;
  }
};

export const getBraintreeAuthTokenFromDomain = (host) => {
  return host.includes(".boingo.com")
    ? BRAINTREE_AUTH_TOKEN_PROD
    : BRAINTREE_AUTH_TOKEN_SANDBOX;
};

// return selfcare domain
export const getSelfcareUrl = (host) => {
  return host.includes(".boingo.com")
    ? selfcareDomainProd
    : selfcareDomainDevQaStg;
};
