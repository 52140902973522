import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getSelfcareUrl } from "../config/config";
import {
  Grid,
  Box,
  Container,
  AppBar,
  Toolbar,
  Typography,
  Drawer,
  useTheme,
  useMediaQuery,
  List,
  ListItem,
  Collapse,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "../assets/stylesheets/landing.css";
import boingoLogo from "../assets/images/Boingo-Logo.svg";
import fiberLogo from "../assets/images/Boingo-Fiber-Logo.svg";
import signInIcon from "../assets/images/Sign-In-Mobile.svg";
import "../assets/stylesheets/faqs.css";
import { content } from "../assets/const/content";
import FaqFooter from "../components/FaqFooter";
import { ReactComponent as FaqHeader } from "../assets/images/FAQ-Header.svg";

export default function StandardFaqs() {
  const [isNavHidden, setIsNavHidden] = useState(false);
  const [open, setOpen] = useState({});

  const handleClick = (index) => {
    setOpen((prevOpen) => ({
      ...prevOpen,
      [index]: !prevOpen[index],
    }));
  };

  const Navbar = () => {
    const [mobileOpen, setMobileOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
    };

    const drawer = (
      <Box>
        <Box className="mobile-nav-container" onClick={handleDrawerToggle}>
          <img src={fiberLogo} />
          <CloseIcon className="close-icon" />
        </Box>

        <Box className="mobile-nav-sub-section">
          <Typography className="lets-start">
            Let&#8217;s start by finding out
            <br /> if Boingo Fiber is available <br /> in your home
          </Typography>
          <Link to="/check-availability" className="avail-btn">
            Check availability
          </Link>
        </Box>
        <Box className="mobile-nav-hr"></Box>
        <Box className="mobile-sign-in-wrapper">
          <a href={getSelfcareUrl(window.location.host)}>
            <img src={signInIcon} />
            Sign In
          </a>
        </Box>
      </Box>
    );

    return (
      <>
        <AppBar
          position="fixed"
          className="app-bar"
          sx={{ backgroundColor: "#fff" }}
        >
          <Toolbar>
            {isMobile ? (
              <>
                {/* Mobile Navigation */}
                <Box
                  className="mobile-nav"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    margin: "0 10px",
                  }}
                >
                  <Grid container justifyContent="space-between">
                    <Grid item xs={6} sx={{ textAlign: "left" }}>
                      <img
                        src={fiberLogo}
                        className="fiber-logo"
                        alt="Fiber Logo"
                      />
                    </Grid>
                    <Grid item xs={3}></Grid>

                    <Grid item xs={2} sx={{ textAlign: "right" }}>
                      <MenuIcon
                        color="primary"
                        aria-label="open drawer"
                        onClick={handleDrawerToggle}
                        sx={{ color: "#000", cursor: "pointer" }}
                      />
                    </Grid>
                  </Grid>

                  <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    anchor="right"
                    onClose={handleDrawerToggle}
                    ModalProps={{ keepMounted: true }}
                  >
                    {drawer}
                  </Drawer>
                </Box>
              </>
            ) : (
              <Container
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  flexFlow: "row",
                }}
              >
                <Grid
                  sx={{
                    width: "auto",
                    alignSelf: "flex-start",
                  }}
                >
                  <Grid
                    item
                    sx={{
                      textAlign: "right",
                      width: "300px",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <img
                      src={fiberLogo}
                      className="fiber-logo"
                      alt="Fiber Logo"
                    />
                  </Grid>
                </Grid>
                <Grid
                  sx={{
                    justifyContent: "flex-end",
                    display: "flex",
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      width: "300px",
                      flexFlow: "row wrap",
                      alignSelf: "flex-end",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Grid item xs={false} sx={{ textAlign: "right" }}>
                      <Link to="/check-availability" className="avail-btn">
                        Check availability
                      </Link>
                    </Grid>
                    <Grid
                      item
                      xs={false}
                      sx={{ textAlign: "center", marginLeft: "20px" }}
                    >
                      <Typography variant="body2">
                        <a href={getSelfcareUrl(window.location.host)}>
                          Sign In
                        </a>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            )}
          </Toolbar>
        </AppBar>
        <Toolbar /> {/* Offset for content */}
      </>
    );
  };
  return (
    <>
      <Box>
        <Box sx={{ textAlign: "center" }}>{!isNavHidden && <Navbar />}</Box>
        <Box className="faq-container">
          <Box className="faq-header faq-header-svg">
            <FaqHeader />
          </Box>
          {content.faqs.map((mainCat, mainIndex) => (
            <Box key={mainIndex} className="faq">
              <Box className="faq-category">
                <Box
                  id="faq1"
                  className="faq-item question"
                  onClick={() => handleClick(mainIndex)}
                >
                  <h2>{mainCat.text}</h2>
                  <Box className="faq-arrow-wrapper">
                    <Box
                      className={`faq-arrow ${
                        open[mainIndex] ? "selected" : ""
                      }`}
                    >
                      <ExpandMoreIcon />
                    </Box>
                  </Box>
                </Box>
                <Collapse
                  sx={{ overflow: "auto" }}
                  in={open[mainIndex]}
                  timeout="auto"
                  unmountOnExit
                >
                  {mainCat.array.map((item, catIndex) => (
                    <Box key={`cat-${catIndex}`} className="faq-item answer">
                      <h3>{item.q}</h3>
                      {item.a.map((answer, answerIndex) => {
                        const answerKey = `answer-${catIndex}-${answerIndex}`;
                        if (answer.type === "p") {
                          return <p key={answerKey}>{answer.html}</p>;
                        }
                        if (answer.type === "bold-line") {
                          return (
                            <p key={answerKey}>
                              <strong>{answer.html}</strong>
                            </p>
                          );
                        }
                        if (answer.type === "list") {
                          return (
                            <ul key={answerKey}>
                              {answer.html.map((listItem, listItemIndex) => (
                                <li
                                  className={listItem?.class}
                                  key={`list-${catIndex}-${answerIndex}-${listItemIndex}`}
                                >
                                  {listItem}
                                </li>
                              ))}
                            </ul>
                          );
                        }
                        if (answer.type === "list-ordered") {
                          return (
                            <ol key={answerKey}>
                              {answer.html.map((listItem, listItemIndex) => (
                                <li
                                  className={listItem?.class}
                                  key={`list-${catIndex}-${answerIndex}-${listItemIndex}`}
                                >
                                  {listItem}
                                </li>
                              ))}
                            </ol>
                          );
                        }
                        if (answer.type === "list-ordered-with-links") {
                          return (
                            <ol key={answerKey}>
                              <li>
                                {answer.html1}
                                <a target="_blank" href={answer.link[0]}>
                                  {answer.html2}
                                </a>
                                {answer.html3}
                                <a target="_blank" href={answer.link[1]}>
                                  {answer.html4}
                                </a>
                              </li>
                              <li>{answer.html5}</li>
                            </ol>
                          );
                        }
                        if (answer.type === "bold-inline") {
                          return (
                            <p key={answerKey}>
                              {answer.html1}
                              <strong>{answer.bold}</strong> {answer.html2}
                            </p>
                          );
                        }
                        if (answer.type === "link-p") {
                          return (
                            <p key={answerKey}>
                              {answer.html1}
                              <a target="_blank" href={answer.link}>
                                {answer.linktext}
                              </a>
                              {answer.html2}
                            </p>
                          );
                        }
                        if (answer.type === "step-p1") {
                          return (
                            <Box key={answerKey}>
                              <h2>{answer.headertext}</h2>
                              <p>
                                {answer.html1} <strong>{answer.html2}</strong>{" "}
                                {answer.html3}
                              </p>
                              <p>{answer.html4}</p>
                              <ul>
                                {answer.html5.map((item, index) => (
                                  <li
                                    key={`step-${catIndex}-${answerIndex}-${index}`}
                                  >
                                    {item}
                                  </li>
                                ))}
                              </ul>
                            </Box>
                          );
                        }
                        if (answer.type === "step-p2") {
                          return (
                            <Box key={answerKey}>
                              <p>{answer.html1}</p>
                              <ul>
                                {answer.html2.map((item, index) => (
                                  <li key={index}>{item}</li>
                                ))}
                              </ul>
                            </Box>
                          );
                        }
                        if (answer.type === "support-p") {
                          return (
                            <Box key={answerKey}>
                              <h2>{answer.header}</h2>
                              <p>{answer.html1}</p>
                              <p>
                                <a target="_blank" href={answer.link}>
                                  {answer.html2}
                                </a>{" "}
                                {answer.html3}
                              </p>
                              <p>
                                {answer.html4}{" "}
                                <a target="_blank" href={"tel:" + answer.tel}>
                                  {answer.html5}
                                </a>{" "}
                                {answer.html6}
                              </p>
                            </Box>
                          );
                        }
                        if (answer.type === "img") {
                          return (
                            <img
                              key={answerKey}
                              src={answer.src}
                              width={"300px"}
                            />
                          );
                        }
                        if (answer.type === "list-with-first-line-bold") {
                          return (
                            <ul key={answerKey}>
                              <li>
                                {answer.html1}
                                <strong>{answer.html2}</strong>
                                {answer.html3}
                              </li>
                              {answer.html4.map((item, index) => (
                                <li key={index}>{item}</li>
                              ))}
                            </ul>
                          );
                        }
                        if (answer.type === "list-bold-headers") {
                          return (
                            <ul key={answerKey}>
                              {answer.html.map((listItem, listItemIndex) => (
                                <li
                                  className={listItem?.class}
                                  key={`list-${catIndex}-${answerIndex}-${listItemIndex}`}
                                >
                                  <strong>{listItem.bold}</strong>
                                  {listItem.text}
                                </li>
                              ))}
                            </ul>
                          );
                        }
                        return null;
                      })}
                    </Box>
                  ))}
                </Collapse>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <FaqFooter />
    </>
  );
}
