import React, { useEffect } from "react";
import { Box, Button } from "@mui/material";
import "../assets/stylesheets/error.css";
import { formatErrorMsg, getQueryParam } from "../config/utils";
import { useNavigate } from "react-router-dom";
import { content } from "../assets/const/content";

export default function Error({ ...props }) {
  const [errors] = React.useState([props.errors]);
  const [formattedErrors, setErrors] = React.useState([]);
  const navigate = useNavigate();

  const renderErrorText = (errors) => {
    for (const errorObj of errors) {
      const formatted = Object.entries(errorObj).length > 1 && formatErrorMsg(errorObj);
      // render statusCode if exists
      const formattedError = errorObj.statusCode ? 
        { statusCode: errorObj.statusCode, message: formatted } : { statusCode: null, message: formatted };

      // disallow duplicates
      setErrors((prev) => {
        return (!prev.some(item => item.message === formatted)) ? [...prev, formattedError]: prev;
      });
    }
  }
  
  useEffect(() => {
    const errorParam = getQueryParam('error');
    const allErrors =  Object.entries(errors).length > 1 ? [...errors] : []; 
    if (errorParam) { allErrors.push(errorParam); }
    allErrors && renderErrorText(allErrors); 
  }, []);

  return (<div className="error-container">
    <div id="error-header">
      <div className="fiberLogo"></div>
    </div>

    <div className="error-container-description">
      {content.errors.description}
    </div>

    <div className="error-wrapper">
    <div className="error-subheader">{content.errors.errorHeader}</div>

      <div className="error-details">
        { formattedErrors.length ? 
          formattedErrors.map((err, index) => (<Box className="error-message" key={index}>
            {err.statusCode && `${err.statusCode}: `} {err.message}
          </Box>))
          : <Box className="error-message">{content.errors.default}</Box>
        }
      </div>
    </div>

    <Button
      className="home-button"
      disableElevation
      variant="contained"
      type="submit"
      onClick={() => navigate('/')}
    >
      Back to Homepage
    </Button>
  </div>
);
}
