import React, { useState, useEffect, useRef, useDebugValue } from "react";
import {
  getDeviceList,
  updateDeviceList,
  addDeviceList,
} from "../services/apiServices";
import {
  Box,
  Typography,
  List,
  ListItem,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";

import { getQueryParam } from "../config/utils";

import GetUserOnline from "../components/GetUserOnline";

const DeviceModelSelector = ({ onDeviceAdd }) => {
  const [deviceModel, setDeviceModel] = useState("");
  const [deviceName, setDeviceName] = useState("");
  const [macAddress, setMacAddress] = useState(getQueryParam("mac") || "");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(
      "device model",
      deviceModel,
      "device name",
      deviceName,
      "MAC",
      macAddress
    );
    onDeviceAdd({
      model: deviceModel,
      name: deviceName,
      mac: macAddress,
    });
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Typography variant="h6" sx={{ mb: 3 }}>
        Add a new device
      </Typography>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>Device Type</InputLabel>
        <Select
          value={deviceModel}
          label="Device Type"
          onChange={(e) => setDeviceModel(e.target.value)}
          required
          sx={{ textAlign: "left" }}
        >
          <MenuItem value="Smart Phone">
            <span className="deviceModel Smart Phone"></span>Phone
          </MenuItem>
          <MenuItem value="Tablet">
            <span className="deviceModel Tablet"></span>Tablet
          </MenuItem>
          <MenuItem value="Computer">
            <span className="deviceModel Computer"></span>Computer
          </MenuItem>
          <MenuItem value="Gaming">
            <span className="deviceModel Gaming"></span>Game Console
          </MenuItem>
          <MenuItem value="TV">
            <span className="deviceModel TV"></span>TV
          </MenuItem>
          <MenuItem value="Smart Device">
            <span className="deviceModel Device"></span>Other
          </MenuItem>
        </Select>
      </FormControl>
      <TextField
        fullWidth
        label="Device Name"
        value={deviceName}
        onChange={(e) => setDeviceName(e.target.value)}
        required
        sx={{ mb: 2 }}
      />
      <Button
        type="submit"
        variant="contained"
        sx={{ fontSize: "16px", boxShadow: "none", mt: 3 }}
      >
        Add Device
      </Button>
    </Box>
  );
};

export const DeviceIDFlow = ({ loginData, onSuccess, onError, closeModal }) => {
  const [deviceList, setDeviceList] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showGetUserOnline, setShowGetUserOnline] = useState(false);
  const [showDeviceModelSelector, setShowDeviceModelSelector] = useState(false);
  const [macAddress, setMacAddress] = useState(getQueryParam("mac") || "");

  const fetchedRef = useRef(false);

  const onClose = () => {
    closeModal();
  };

  const fetchDeviceList = async () => {
    if (fetchedRef.current) return; // If already fetched, don't fetch again
    fetchedRef.current = true; // Mark as fetched

    try {
      setIsLoading(true);
      const response = await getDeviceList(loginData);
      console.log(response.data);
      if (response.data.devices && response.data.devices.length > 0) {
        setDeviceList(response.data.devices);
        setShowDeviceModelSelector(false);
      } else {
        setShowDeviceModelSelector(true);
      }
      setIsLoading(false);
    } catch (err) {
      console.error("Error fetching device list:", err);
      setError("Failed to fetch device list. Please try again.");
      setIsLoading(false);
      if (typeof onError === "function") {
        onError(err.response.data.message);
      }
    }
  };

  useEffect(() => {
    fetchDeviceList();
  }, [loginData, onError]);

  const handleDeviceSelect = async (device) => {
    setSelectedDevice(device);
    console.log(device);

    if (!macAddress) {
      console.error("MAC address is missing");
      if (typeof onError === "function") {
        onError("MAC address is missing. Please try again or contact support.");
      }
      return;
    }

    //always use mac address from query params to update
    let deviceMac = {
      mac: macAddress,
    };

    try {
      const response = await updateDeviceList(device.device_id, deviceMac);
      if (response.status === 200) {
        console.log("Device updated successfully");
        setShowGetUserOnline(true);
      } else {
        console.error("Failed to update device");
        if (typeof onError === "function") {
          onError("Failed to update device. Please try again.");
        }
      }
    } catch (err) {
      console.error("Error updating device:", err);
      if (typeof onError === "function") {
        if (err.response !== undefined && err.response.status === 409 && err.response.data !== undefined && err.response.data.message !== undefined && err.response.data.code === 100)
        {
          onError(err.response.data.message)
        }
        else {
          onError(err.message);
        }
        
      }
    }
  };

  const handleDeviceAdd = async (newDevice) => {
    try {
      if (!newDevice.mac) {
        throw new Error("MAC address needs to be defined");
      }
      //
      const response = await addDeviceList(newDevice);
      if (response.status === 201) {
        console.log("Device added successfully");
        setShowGetUserOnline(true);
        //await fetchDeviceList();
      } else {
        console.error("Failed to add device");
        if (typeof onError === "function") {
          onError("Failed to add device. Please try again.");
        }
      }
    } catch (err) {
      console.error("Error adding device:", err);
      if (typeof onError === "function") {
        onError(err.message);
      }
    }
  };

  const handleGetUserOnlineSuccess = (data) => {
    onSuccess({ ...loginData, selectedDevice: selectedDevice, ...data });
  };

  const handleGetUserOnlineError = (error) => {
    if (typeof onError === "function") {
      onError(error);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (showGetUserOnline) {
    return (
      <GetUserOnline
        customerDataObj={loginData}
        onSuccess={handleGetUserOnlineSuccess}
        onError={handleGetUserOnlineError}
      />
    );
  }

  if (showDeviceModelSelector) {
    return <DeviceModelSelector onDeviceAdd={handleDeviceAdd} />;
  }

  return (
    <Box>
      <Typography variant="h6" sx={{ mb: 2, lineHeight: 1 }}>
        Select the device you are logging in with
      </Typography>
      <List>
        {deviceList.map((device) => (
          <ListItem
            key={device.id || device.name}
            onClick={() => handleDeviceSelect(device)}
            sx={{
              cursor: "pointer",
              border: "1px solid #ccc",
              borderRadius: "4px",
              padding: "7px 10px",
              margin: "8px 0",
              "&:hover": {
                backgroundColor: "#f0f0f0",
              },
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box sx={{ mr: 1 }}>
              <Box className={`deviceModel ${device.model}`}></Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="body1" sx={{ fontFamily: "Figtree-Bold" }}>
                {device.name || `Device ${device.id}`}
              </Typography>
              <Typography variant="body2" sx={{ color: "#ccc" }}>
                {device.mac}
              </Typography>
            </Box>
          </ListItem>
        ))}
      </List>
      <Typography
        onClick={onClose}
        variant="body2"
        sx={{ textDecoration: "underline", cursor: "pointer" }}
      >
        Cancel
      </Typography>
    </Box>
  );
};
