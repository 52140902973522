import { Box, Typography } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useLocation } from "react-router-dom";
import LinkWithQuery from "./LinkWithQuery"; // Import the LinkWithQuery component

export default function Breadcrumbs() {
  const location = useLocation();

  return (
    <Box
      className="breadcrumbs"
      sx={{
        display: "flex",
        alignItems: "center",
        width: { xs: "85%", md: "450px", lg: "750px" },
      }}
    >
      <LinkWithQuery to="/" className="fiber-plan-link">
        Fiber Plan
      </LinkWithQuery>
      <KeyboardArrowRightIcon sx={{ color: "#d8d8d8" }} />

      <LinkWithQuery to="/signup-form" className="fiber-plan-link">
        Account
      </LinkWithQuery>
      <KeyboardArrowRightIcon sx={{ color: "#d8d8d8" }} />

      <LinkWithQuery to="/payment" className="fiber-plan-link disabled" disabled={true}>
        Checkout
      </LinkWithQuery>
    </Box>
  );
}
