import { content } from "../assets/const/content";
import {
  Box,
  TableFooter,
  Typography,
  Popover,
  List,
  ListItemText,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { ReactComponent as WifiSystem } from "../assets/images/Wi-Fi-System-Icon.svg";
import { ReactComponent as GoWireless } from "../assets/images/subhead-go-wireless.svg";
import { ReactComponent as HereToHelp } from "../assets/images/subhead-were-here-to-help.svg";
import "../assets/stylesheets/welcome.css";

import Header from "../components/Header";
import SetupSteps from "../components/SetupSteps";
import React, { useState, useEffect } from "react";
import WelcomeFooter from "../components/WelcomeFooter";

import { getSelfcareUrl } from "../config/config";

export default function Welcome() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handlePopOverClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="fiber-welcome-container">
        <div id="welcome-header" sx={{ width: "" }}>
          <Box className="welcome-to">{content.welcomeWelcomeTo}</Box>
          <Header />
        </div>
        <Box className="container welcome">
          <Box className="wrapper welcome">
            <Typography variant="h5" sx={{ fontFamily: "Figtree-Regular" }}>
              {content.welcomeSubhead1}
            </Typography>
          </Box>
        </Box>
        <Box className="go-wireless-container">
          <Box className="go-wireless-wrapper">
            <Box className="welcome-subhead-svg gw-item wireless">
              <Box>
                <GoWireless />
              </Box>
            </Box>
            <Box className="gw-item wifi">
              <Box>
                <WifiSystem />
              </Box>
            </Box>
            <Box className="gw-item additional">
              <p>{content.welcomeWyn3}</p>
            </Box>
          </Box>
        </Box>
        <Box className="setup-in-seconds-container">
          <Box className="setup-in-seconds-wrapper">
            <Box className="welcome-subhead-svg">
              <HereToHelp />
              <Box className="here-to-help-wrapper">
                <Box className="welcome-subhead-svg here-to-help">
                  <Box className="helpSubhead1" sx={{ textAlign: "center" }}>
                    <Typography>
                      {content.welcomeHelpSubhead1}
                      {!isMobile ? <br /> : ""}{" "}
                      <a target="_blank" href="https://support.boingo.com/s/">
                        {content.welcomeHelpSubhead2}
                      </a>
                      {content.welcomeHelpSubhead3}
                    </Typography>
                  </Box>
                </Box>
                <Box className="here-to-help-img"></Box>
                <Button
                  disableElevation
                  sx={{
                    mb: !isMobile ? "50px" : "24px",
                    "&.MuiButton-root": { mt: isMobile ? "24px" : "36px" },
                  }}
                  variant="contained"
                  type="submit"
                  className="welcome-faq-button"
                >
                  <a href="https://support.boingo.com/BarracksAndDormsFiber/s/">
                    see FAQs
                  </a>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <WelcomeFooter />
      </div>
    </>
  );
}
