// csrfService, is independent of axiosInstance, and use to fetch the csrf token for POST, PUT, PATCH, DELETE requests
import axios from "axios";
import { getEnterpriseUrl } from "../config/config";

const enterpriseUrl = getEnterpriseUrl(window.location.host);

const csrfApiService = axios.create({
  withCredentials: true,
  baseURL: enterpriseUrl,
});

let csrfToken = null;

export const fetchCsrfToken = async () => {
  if (csrfToken) {
    return csrfToken;
  }

  try {
    const response = await csrfApiService.get("/csurf/token");
    csrfToken = response.data.csrfToken;
    return csrfToken;
  } catch (error) {
    console.error("Error fetching CSRF token:", error);
    throw error;
  }
};

export const clearCsrfToken = () => {
  csrfToken = null;
};
