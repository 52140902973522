// SubmitButton.js
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { VENUE_DATA } from "../assets/const/constant";
import { createCustomerService } from "../services/apiServices";
import {
  generateGUID,
  deleteCookieByName,
  getQueryParam,
  getVenueDataFromVenueId,
} from "../config/utils";
import {
  Modal,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import FiberLogo from "../assets/images/Boingo-Fiber-Logo-Horizontal.svg";
import GetUserOnline from "./GetUserOnline";

function SubmitButtonForSignup({
  finalCustomerInfo,
  braintreeInstanceObject,
  deviceDataKount,
  paymentType,
  setPaymentType,
  milstarCCGlobal,
  setMilstarCCGlobal,
  paymentAndLoginValidationErrors,
}) {
  const { venueId } = useParams();
  const venueData = getVenueDataFromVenueId(venueId);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setIsLoading(false);
    setErrorMessage(null);
  };
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [showGetUserOnline, setShowGetUserOnline] = useState(false);
  const [customerDataObj, setCustomerDataObj] = useState(null);

  const [dialogMessage, setDialogMessage] = useState(
    "Please wait while we create your account..."
  );

  useEffect(() => {
    if (paymentType === "applePay" || paymentType === "PayPal") {
      setIsSubmitDisabled(false);
    }
    if (paymentType === "hostedField" || paymentType === "milstar") {
      setIsSubmitDisabled(true);
      // Define all the keys that should be in paymentAndLoginValidationErrors, (values are same for hostedfields and milstar)
      const requiredErrorKeys = [
        "number",
        "cvv",
        "expirationDate",
        "postalCode",
        "username",
        "password",
      ];

      // Check if all keys are set in paymentValidationErrors
      const allKeysSet = requiredErrorKeys.every(
        (key) => key in paymentAndLoginValidationErrors
      );

      if (allKeysSet) {
        // Check if all error messages are empty
        const allErrorsResolved = Object.values(
          paymentAndLoginValidationErrors
        ).every((error) => error === "");
        setIsSubmitDisabled(!allErrorsResolved);
      }
    }
  }, [paymentAndLoginValidationErrors, paymentType]);

  const handleAuthSuccess = (data) => {
    console.log("Authentication successful", data);
    setDialogMessage("Authentication successful. Redirecting...");
    setTimeout(() => {
      // Get the current hash (without the '#')
      const currentHash = window.location.hash.slice(1);
      // Extract the base path (e.g., 'aftmthm')
      const basePath = currentHash.split("/")[1];
      // Construct the new hash
      const newHash = `/${basePath}/welcome`;
      window.location.hash = newHash;
    }, 2000);
  };

  const handleAuthError = (error) => {
    console.error("Authentication failed", error);
    let errorMessage = "Authentication failed";

    if (error.includes("Missing required fields:")) {
      errorMessage = `${error}. Please try again or contact support.`;
    } else {
      errorMessage = `${error}. Please try again.`;
    }

    setErrorMessage(errorMessage);
    setIsLoading(false);
    setDialogMessage(""); // Clear the success message
  };

  const handleSignupAndPayment = async (event) => {
    event.preventDefault();

    handleOpen();
    setIsLoading(true);
    setDialogMessage("Please wait while we create your account...");

    let paymentTypeObj = {};

    // If User selects Milstar payment
    if (paymentType === "milstar") {
      paymentTypeObj.payment_cc = milstarCCGlobal;
      createCustomerWithPayment(paymentTypeObj);
    } else if (paymentType === "applePay" || paymentType === "PayPal") {
      paymentTypeObj.payment_braintree = {
        ...braintreeInstanceObject,
        device_data: deviceDataKount,
      };
      createCustomerWithPayment(paymentTypeObj);
    }
    // If User selects Braintree Hosted Fields (default)
    else if (paymentType === "hostedField") {
      braintreeInstanceObject.tokenize(async function (tokenizeErr, payload) {
        if (tokenizeErr) {
          console.error("Error hosted fields tokenizing:", tokenizeErr);
          setErrorMessage(tokenizeErr.message);
          setIsLoading(false);
          return;
        }

        paymentTypeObj.payment_braintree = {
          nonce: payload.nonce,
          payment_type: "Credit Card",
          device_data: deviceDataKount,
        };
        createCustomerWithPayment(paymentTypeObj);
      });
      return false;
    }
  };

  const createCustomerWithPayment = async (paymentObj) => {
    //prepare customer data object
    let createCustomerDataObj = {
      ...finalCustomerInfo,
      ...paymentObj,
      product_codes: [venueData.product_code],
      transaction_id: generateGUID(),
      venue_id: venueData.venue_id,
      scc: `BRD${venueId.toUpperCase()}`,
      GSID: getQueryParam("gsid")
        ? getQueryParam("gsid")
        : generateGUID().toUpperCase(),
    };

    try {
      const response = await createCustomerService(createCustomerDataObj);
      console.log("Successful customer creation: ", response.data);
      deleteCookieByName("customerData");
      setCustomerDataObj(createCustomerDataObj);
      setShowGetUserOnline(true);
      setDialogMessage("Account created. Authenticating...");
    } catch (error) {
      console.log("Failed to create customer: ", error);
      setIsLoading(false);
      error.message && error.response.data
        ? setErrorMessage(error.response.data.message)
        : setErrorMessage(error.message);
    }
  };

  return (
    <>
      {/* {JSON.stringify(paymentType)} */}
      {/* {JSON.stringify(paymentAndLoginValidationErrors)} */}
      {/* {JSON.stringify(finalCustomerInfo)} */}

      <Button
        disabled={isSubmitDisabled || isLoading}
        sx={{ mb: "100px", width: "100%" }}
        disableElevation
        variant="contained"
        type="submit"
        onClick={handleSignupAndPayment}
      >
        Complete signup
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <img src={FiberLogo} alt="Fiber Logo" />
        </DialogTitle>
        <DialogContent>
          <Box sx={{ textAlign: "center" }}>
            {isLoading && <CircularProgress sx={{ m: 3 }} />}
            {!errorMessage && (
              <Typography sx={{ my: 5 }} variant="h6">
                {dialogMessage}
              </Typography>
            )}
            {errorMessage && (
              <>
                <Typography sx={{ mt: 2 }} color="error">
                  {errorMessage}
                </Typography>

                <Button
                  sx={{
                    margin: "15px auto 0",
                    backgroundColor: "var(--blue)",
                    color: "white",
                  }}
                  onClick={handleClose}
                >
                  Close
                </Button>
              </>
            )}
          </Box>
        </DialogContent>
      </Dialog>

      {showGetUserOnline && customerDataObj && (
        <GetUserOnline
          customerDataObj={customerDataObj}
          onSuccess={handleAuthSuccess}
          onError={handleAuthError}
        />
      )}
    </>
  );
}

export default SubmitButtonForSignup;
