import axios from "axios";
import { getEnterpriseUrl } from "../config/config";
import { fetchCsrfToken } from "./csurfService";

let enterpriseUrl = getEnterpriseUrl(window.location.host);

const apiService = axios.create({
  baseURL: enterpriseUrl,
  withCredentials: true,
  //enable for https localhost testing for apple pay, do: npm run https for https localhost testing
  //baseURL: "https://enterprise.boingogcpdev.com/portal/api",
});

// Add a request interceptor
apiService.interceptors.request.use(
  async (config) => {
    const method = config.method?.toUpperCase();

    // Check if the request is to the CSRF token endpoint
    if (
      ["POST", "PUT", "PATCH", "DELETE"].includes(method) &&
      config.url !== "/csurf/token"
    ) {
      const csrfToken = await fetchCsrfToken();
      config.headers["X-CSRF-Token"] = csrfToken;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor
apiService.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      console.error("Error response:", {
        status: error.response.status,
        data: error.response.data,
      });
    } else {
      console.error("Error message:", error.message);
    }
    return Promise.reject(error);
  }
);

export default apiService;
