import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";

const LinkWithQuery = ({ to, children, className, ...props }) => {
  const location = useLocation();
  const { venueId } = useParams();

  // Construct the new path with venueId
  const newTo = venueId ? `/${venueId}${to}` : to;

  // Preserve existing query parameters
  const search = location.search || "";

  // Check if the current path matches the link's destination
  const isActive = location.pathname === newTo;

  return (
    <Link
      to={!props.disabled ? `${newTo}${search}` : '#'}
      aria-disabled={props.disabled ? "true" : "false"}
      className={`${className || ""} ${isActive ? "active-link" : ""}`}
      {...props}
    >
      {children}
    </Link>
  );
};

export default LinkWithQuery;
