import React, { useState, useCallback } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import GetUserOnline from "./GetUserOnline";
import { DeviceIDFlow } from "./DeviceIDFlow";

import "../assets/stylesheets/modal.css";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { content } from "../assets/const/content";

export const LoginModal = ({ open, onClose, onLoginSuccess }) => {
  const [showDeviceIDFlow, setShowDeviceIDFlow] = useState(false);
  const [loginData, setLoginData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showLoginLoader, setShowLoginLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
  });

  const onSubmit = (data) => {
    setIsLoading(true);
    setLoginData(data);
    setShowDeviceIDFlow(true);
    setErrorMessage(null);
  };

  const handleAuthSuccess = useCallback(
    (data) => {
      console.log("Authentication successful", data);
      onLoginSuccess(data);
      setShowLoginLoader(true);
    },
    [onLoginSuccess]
  );

  const handleAuthError = (error) => {
    console.error("Authentication failed", error);
    let errorMessage = "Authentication failed";

    if (error.includes("Missing required fields:")) {
      errorMessage = `${error}. Please try again or contact support.`;
    } else {
      errorMessage = `${error}`;
    }

    setErrorMessage(errorMessage);
    setIsLoading(false);
    setShowDeviceIDFlow(false);
  };

  const handleClose = () => {
    setErrorMessage(null);
    setShowDeviceIDFlow(false);
    setLoginData(null);
    setIsLoading(false);
    reset();
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="login-modal-title"
      aria-describedby="login-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "35%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 300,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          border: 0,
          textAlign: "center",
        }}
      >
        {!showDeviceIDFlow ? (
          <>
            <Typography
              id="login-modal-title"
              fontFamily={"Figtree-Bold"}
              variant="h5"
              component="h2"
              gutterBottom
              textAlign="center"
            >
              Log In
            </Typography>
            
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name="username"
                control={control}
                defaultValue=""
                rules={{
                  required: "Username is required",
                  pattern: {
                    value: /^[a-zA-Z0-9.-_]{4,}$/,
                    message:
                      "Username must be at least 4 characters long and can only contain letters, numbers, underscores, and periods.",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Username *"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!errors.username}
                    helperText={errors.username?.message}
                    onBlur={field.onBlur}
                  />
                )}
              />
              <Controller
                name="password"
                control={control}
                defaultValue=""
                rules={{
                  required: "Password is required",
                  pattern: {
                    value: /^.{6,16}$/,
                    message: "Password must be at least 6 characters long.",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="form-input"
                    required
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    id="password"
                    type={showPassword ? "text" : "password"}
                    label="Password"
                    onBlur={field.onBlur}
                    error={!!errors.password}
                    helperText={errors.password?.message}
                    color="secondary"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            className="pw-icon"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={(event) => event.preventDefault()}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    /> 
                )}
              />
              {errorMessage && (
                <Typography
                  color="error"
                  sx={{ fontSize: "14px", marginTop: 2 }}
                >
                  {errorMessage}
                </Typography>
              )}
              <Typography mt="4px"><a href={content.resetCredentialsHref}>Forgot username or password?</a></Typography>
              <Button
                type="submit"
                color="primary"
                sx={{
                  boxShadow: "none",
                  height: "50px",
                  paddingX: 8,
                  mt: 3,
                  backgroundColor: isLoading ? "#808080" : undefined,
                  "&:hover": {
                    backgroundColor: isLoading ? "#808080" : undefined,
                  },
                }}
                disabled={isLoading}
              >
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Get Online"
                )}
              </Button>
            </form>
            
          </>
        ) : (
          <DeviceIDFlow
            loginData={loginData}
            onSuccess={handleAuthSuccess}
            onError={handleAuthError}
            closeModal={handleClose}
          />
        )}
        {showLoginLoader && (
          <Box sx={{ marginY: 4 }}>
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Modal>
  );
};
