import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import {
  Box,
  Container,
  Select,
  TextField,
  Typography,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";

import "../assets/stylesheets/createAccount.css";

import { constructUrl } from "../config/utils";

import Breadcrumbs from "../components/Breadcrumbs";
import ProductSection from "../components/ProductSection";
import {
  formatPhoneNumber,
  validateInput,
  getCookieByName,
  saveToCookie,
  getQueryParam,
} from "../config/utils";

import YoureInImage from "../assets/images/youre-in.svg";
import DownArrowImg from "../assets/images/Down-Arrow.svg";
import fiberLogo from "../assets/images/Boingo-Fiber-Logo.svg";

export default function SignupForm({
  register,
  handleSubmit,
  watch,
  errors,
  finalCustomerInfo,
  setFinalCustomerInfo,
  queryParams,
}) {
  const [customerInfo, setCustomerInfo] = useState({
    username: "",
    password: "",
    email: "",
    first_name: "",
    last_name: "",
    phone_number: "",
  });

  const [signUpValidationError, setSignUpValidationError] = useState({
    username: "",
    password: "",
    email: "",
    first_name: "",
    last_name: "",
    phone_number: "",
  });

  const productSectionRef = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();
  const { venueId } = useParams();

  // Function to handle click on down-arrow image
  const scrollToProductSection = () => {
    productSectionRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const usernameRegex = /^[A-Za-z0-9_]{6,}$/i;
  const passwordRegex = /^.{6,16}$/;
  const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  const nameRegex = /^[A-Za-z0-9_]+$/i;
  const phoneNumberRegex = /^[0-9-]+$/i;

  const signupFormValidation = (value, regex) => {
    return validateInput(value, regex);
  };

  const handleChange = (event) => {
    if (event.target.id === "phone_number") {
      let formattedPhone = formatPhoneNumber(event);
      setCustomerInfo({
        ...customerInfo,
        phone_number: formattedPhone,
      });
    } else {
      setCustomerInfo({
        ...customerInfo,
        [event.target.id]: event.target.value,
      });
    }
  };

  const handleBlur = (event) => {
    const { id, value } = event.target;
    let error = "";

    switch (id) {
      case "email":
        if (!signupFormValidation(value, emailRegex)) {
          error = "Invalid email format";
        }
        break;
      case "username":
        if (!signupFormValidation(value, usernameRegex)) {
          error = "Invalid username or minimum 6 characters";
        }
        break;
      case "password":
        if (!signupFormValidation(value, passwordRegex)) {
          error = "Password has to be between 6-16 characters";
        }
        break;
      case "first_name":
        if (!signupFormValidation(value, nameRegex)) {
          error = "Invalid first name";
        }
        break;
      case "last_name":
        if (!signupFormValidation(value, nameRegex)) {
          error = "Invalid last name";
        }
        break;
      case "phone_number":
        if (
          !signupFormValidation(value, phoneNumberRegex) ||
          value.length !== 12
        ) {
          error = "Invalid phone number";
        }
        break;
    }

    setSignUpValidationError({ ...signUpValidationError, [id]: error });
  };

  const onSubmitForm = (event) => {
    // Check if there are any validation errors
    const hasErrors = Object.values(signUpValidationError).some(
      (error) => error !== ""
    );

    // If there are no errors, update customerInfo object and redirect to the payment page
    if (!hasErrors) {
      //add 1  and space then strip "-" from phone_number
      const updatedPhoneNumber =
        "1 " + customerInfo.phone_number.replace(/-/g, "");

      setFinalCustomerInfo({
        ...customerInfo,
        phone_number: updatedPhoneNumber,
      });

      saveToCookie({ ...customerInfo });

      // Use the helper function to construct the URL and navigate
      const paymentUrl = constructUrl("/payment", venueId, location.search);
      navigate(paymentUrl);
    } else {
      return;
    }
  };

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);

    const customerDataCookie = getCookieByName("customerData");
    if (customerDataCookie) {
      const customerData = JSON.parse(customerDataCookie);
      setCustomerInfo((prevState) => ({
        ...prevState,
        first_name: customerData.first_name || "",
        last_name: customerData.last_name || "",
        email: customerData.email || "",
        phone_number: customerData.phone_number || "",
      }));
    }
  }, []);

  return (
    <>
      <Box className="signup-container">
        <img src={fiberLogo} className="fiber-logo" />
        <Typography className="signup-subtitle">
          It&#8217;s time to get in the fast lane&#33;
        </Typography>
      </Box>

      <div ref={productSectionRef}>
        <ProductSection />
      </div>
      <Breadcrumbs />

      <Box className="signup-form-wrapper">
        <Box className="user-info">
          <Typography className="create-account-title">
            Create your account
          </Typography>

          <form className="signup-form" onSubmit={handleSubmit(onSubmitForm)}>
            <Box className="info-wrapper">
              <TextField
                className="form-input"
                required
                fullWidth
                type="text"
                margin="dense"
                variant="outlined"
                id="first_name"
                label="First Name"
                value={customerInfo.first_name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!signUpValidationError.first_name}
                helperText={signUpValidationError.first_name}
                color="secondary"
              />

              <TextField
                className="form-input"
                required
                fullWidth
                type="input"
                margin="dense"
                variant="outlined"
                id="last_name"
                label="Last Name"
                value={customerInfo.last_name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!signUpValidationError.last_name}
                helperText={signUpValidationError.last_name}
                color="secondary"
              />

              <TextField
                className="form-input"
                required
                fullWidth
                margin="dense"
                variant="outlined"
                id="email"
                label="Email"
                value={customerInfo.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!signUpValidationError.email}
                helperText={signUpValidationError.email}
                color="secondary"
              />

              <Box sx={{ display: "flex", gap: 0 }}>
                <Select
                  color="secondary"
                  margin="dense"
                  variant="outlined"
                  sx={{
                    marginTop: "8px",
                    height: "56px",
                    width: "120px",
                    borderRadius: "4px 0 0 4px ",
                    backgroundColor: "#fff",
                    opacity: "1",
                    position: "relative",
                    zIndex: "10",
                  }}
                  id="areacode"
                  name="areacode"
                  value={"1"}
                  //onChange={handleSelectChange}
                >
                  <MenuItem value={"1"}>USA(+1)</MenuItem>
                </Select>

                <TextField
                  sx={{
                    width: "100%",
                    marginLeft: "-3px",
                  }}
                  className="form-input"
                  required
                  margin="dense"
                  variant="outlined"
                  id="phone_number"
                  label="Phone Number"
                  value={customerInfo.phone_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!signUpValidationError.phone_number}
                  helperText={signUpValidationError.phone_number}
                  color="secondary"
                  inputProps={{
                    "aria-label": "phone number",
                  }}
                />
              </Box>
            </Box>
            <Button
              sx={{ mb: "100px", width: "100%", height: "50px" }}
              disableElevation
              variant="contained"
              type="submit"
            >
              Continue to checkout
            </Button>
          </form>
        </Box>
      </Box>
      {/* {JSON.stringify(customerInfo)} */}
    </>
  );
}
