import { content } from "../assets/const/content";
import { Box, TableFooter, Typography } from "@mui/material";
import { ReactComponent as Step1Image } from "../assets/images/Step-1-Illustration.svg";
import { ReactComponent as Step2Image } from "../assets/images/Step-2-Illustration.svg";
import { ReactComponent as Step3Image } from "../assets/images/Step-3-Illustration.svg";
import { ReactComponent as Step1Number } from "../assets/images/Number-1-Red.svg";
import { ReactComponent as Step2Number } from "../assets/images/Number-2-Red.svg";
import { ReactComponent as Step3Number } from "../assets/images/Number-3-Red.svg";
import { useEffect, useState } from "react";

function SetupSteps() {
  const [activeStep, setActiveStep] = useState(1);
  const [stepDescription, setStepDescription] = useState("");
  const [stepSubdescription, setSubdescription] = useState("");

  useEffect(() => {
    updateStepContent(1);
  }, []);

  const updateStepContent = (stepNumber) => {
    setStepDescription(content["welcomeSetupDescription" + stepNumber]);
    setSubdescription(content["welcomeSetupSubdescription" + stepNumber]);
  };

  function chooseStep(stepNumber) {
    setActiveStep(stepNumber);
    updateStepContent(stepNumber);
  }

  const getStepClassName = (stepNumber) => {
    return `setup-steps-numbers number ${
      activeStep === stepNumber ? "active" : ""
    }`;
  };

  const renderStepImage = () => {
    switch (activeStep) {
      case 1:
        return <Step1Image />;
      case 2:
        return <Step2Image />;
      case 3:
        return <Step3Image />;
      default:
        return null;
    }
  };

  return (
    <Box className="steps-wrapper">
      <Box className="setup-steps">
        <Box className="setup-steps-header">{content.welcomeSetupHeader1}</Box>
        <Box className="setup-steps-numbers">
          <Box
            id="step1"
            onClick={() => chooseStep(1)}
            className={getStepClassName(1)}
          >
            <Step1Number />
          </Box>
          <Box
            id="step2"
            onClick={() => chooseStep(2)}
            className={getStepClassName(2)}
          >
            <Step2Number />
          </Box>
          <Box
            id="step3"
            onClick={() => chooseStep(3)}
            className={getStepClassName(3)}
          >
            <Step3Number />
          </Box>
        </Box>
        <Box className="setup-steps-description">{stepDescription}</Box>
        <Box className="setup-steps-subdescription">{stepSubdescription}</Box>
      </Box>
      <Box className="setup-image">{renderStepImage()}</Box>
    </Box>
  );
}

export default SetupSteps;
